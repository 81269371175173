import React from "react";

import {
  chakra,
  Heading,
  Grid,
  GridItem,
  Text,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";

import Footer from "../components/Footer";
import Framed from "../components/Framed";
import Header from "../components/Header";
import Link from "../components/Link";
import { SpecificHeaders } from "../components/SocialHeaders";
import { useHeaderImage } from "../components/images/blurryAisleHeader";

export default function GuidesPage() {
  return (
    <>
      <Header imageHook={useHeaderImage}>
        <SpecificHeaders title="Guides" path="/guides" />
        <Heading as="p">Which brands are slave free?</Heading>
        <Text fontSize="2xl" lineHeight="1.4" mt="1rem">
          Learn about the issues involved in food supply chains and how you can
          support human rights while shopping.
        </Text>
      </Header>
      <Framed as="main" id="main">
        <Grid
          templateColumns={[
            "1fr",
            null,
            "200px fit-content(65ch)",
            null,
            null,
            "276px fit-content(65ch)",
          ]}
          rowGap={["1rem", null, "60px"]}
          py={["60px", null, "90px"]}
        >
          <GridItem>
            <Heading fontFamily="body" color="brand.lightBlue">
              Shopping <chakra.br display={["none", null, "inline"]} />
              Guides
            </Heading>
          </GridItem>
          <GridItem>
            <Text textStyle="boldList">
              <UnorderedList>
                <ListItem>
                  <Link href="/guides/cocoa/">Cocoa</Link>
                </ListItem>
                <ListItem>
                  <Link href="/guides/sugar/">Sugar</Link>
                </ListItem>
                <ListItem>
                  <Link href="/guides/morning-tea/">Morning tea</Link> - tea,
                  coffee, biscuits and more!
                </ListItem>
                <ListItem>
                  <Link href="/guides/tinned-tuna-and-sardines/">
                    Tinned Tuna, Mackerel &amp; Sardines
                  </Link>
                </ListItem>
                <ListItem>
                  <Link href="/guides/salmon/">Salmon</Link>
                </ListItem>
                <ListItem>
                  <Link href="/guides/shellfish-crustaceans/">
                    Shellfish &amp; Crustaceans
                  </Link>
                </ListItem>
              </UnorderedList>
            </Text>
          </GridItem>
          <GridItem pt={["2rem", "3rem", 0]} colStart="1">
            <Heading fontFamily="body" color="brand.lightBlue">
              Research <chakra.br display={["none", null, "inline"]} />
              Notes
            </Heading>
          </GridItem>
          <GridItem>
            <Text textStyle="simple">
              {/* No <p> tag here to avoid top margin */}
              Cocoa and sugar products with trusted certifications, or grown in
              countries with good labour laws, are reliably slave-free. In
              contrast, for many other food categories there are currently no
              reliable human rights certifications. This is a particular issue
              in the fishing industry, as the remote nature of much commercial
              fishing means fish products can be impacted by slavery even in
              jurisdictions with good labour laws.
              <p>
                We have done our own research to identify slave-free fish
                products. These notes provide a record so you can understand and
                critique our recommendations. We have more recently expanded
                this research to include other food products with a high risk of
                slavery in their supply chains, and hope to do more over time.
              </p>
              <p>
                We intend to produce a shopping guide to go along with each of
                these research notes. For those where we have not yet done so,
                look for the summary pdf or recommendations section within each
                note.
              </p>
            </Text>
            <Text textStyle="boldList" pt={["1rem", null, "40px"]} pb="50px">
              <UnorderedList>
                <ListItem>
                  <Link href="/research/fish-salmon/">Salmon</Link>
                </ListItem>
                <ListItem>
                  <Link href="/research/fish-tinned/">
                    Tinned Tuna, Sardines &amp; Mackerel
                  </Link>
                </ListItem>
                <ListItem>
                  <Link href="/research/seafood/">
                    Shellfish, Crustaceans &amp; Squid
                  </Link>
                </ListItem>
                <ListItem>
                  <Link href="/research/fish-petfood/">Fish in Pet Food</Link>
                </ListItem>
                <ListItem>
                  <Link href="/research/fish-supplements/">
                    Fish Oil & Omega 3 Supplements
                  </Link>
                </ListItem>
                <ListItem>
                  <Link href="/research/koko-samoa/">Koko Samoa</Link>
                </ListItem>
                <ListItem>
                  <Link href="/research/nuts-coconuts-rice/">
                    Nuts, Coconuts & Rice
                  </Link>
                </ListItem>
                <ListItem>
                  <Link href="/research/tea/">Tea</Link>
                </ListItem>
                <ListItem>
                  <Link href="/research/coffee/">Coffee</Link>
                </ListItem>
                <ListItem>
                  <Link href="/research/drink-concentrates/">
                    Drink Concentrates
                  </Link>
                </ListItem>
                <ListItem>
                  <Link href="/research/australian-sugar/">
                    Australian Sugar
                  </Link>
                </ListItem>
              </UnorderedList>
            </Text>
          </GridItem>
        </Grid>
      </Framed>
      <Footer />
    </>
  );
}
